@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.fclould-ekyc {
  --color-primary: #1080ec;
  --color-black: #313c4b;
  --color-gray: #7a8086;
  --color-white: #ffffff;
  --color-stroke: #f6f6f6;
  --color-assert: #0db473;
  --color-error: #ff7878;

  font-family: "Roboto", -apple-system;
  line-height: 20px;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --max-width-ekyc: 500px;
  --max-width-ekyc-camera: 500px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ekyc-form-item {
  padding: 12px 0 8px;
  border-bottom: var(--color-stroke) solid 1px;
}

.ekyc-form__lable {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray);
  margin-bottom: 4px;
}

.ekyc-form__input-text__content {
  font-size: 14px;
  color: var(--color-black);
  font-weight: 600;
  padding: 4px 0;
  border: none !important;
  outline: none;
  background: none;
}

.ekyc-form__input-text__content:disabled {
  color: var(--color-black);
}

.ekyc-form__input-text__content::placeholder {
  font-weight: 400;
  font-style: italic;
}

.ekyc-form__input-text__icon,
.ekyc-form__input-date__icon {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 10px;
  width: 28px;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.ekyc-form__input-date input {
  font-size: 14px;
  color: var(--color-black);
  font-weight: 600;
  outline: none;
  border: none;
}

.ekyc-form__input-select .custom-select {
  font-size: 14px;
  color: var(--color-black);
  font-weight: 600;
}

.ekyc-form__input-select .custom-select > div {
  border: none !important;
  outline: none !important;
}

.ekyc-form__input-select .custom-select > div > div {
  padding-left: 0 !important;
}

.ekyc-form__input-text textarea {
  -webkit-box-sizing: border-box;
  padding-right: 40px;
}

.text-title {
  font-size: 30px;
  font-weight: 400;
  margin: 0 auto 24px;
}

.ekyc-form__text-error {
  font-size: 12px;
  color: var(--color-error);
  margin: 4px 0 -8px;
}

.list-warning {
  margin: 0;
  padding: 0 5px;
}

.list-warning > :last-child {
  margin-bottom: 0;
}

.warning-note {
  margin-bottom: 18px;
}

.warning-note__icon,
.error-note__icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.warning-note__text {
  color: var(--color-primary);
}

.error-note__text {
  color: rgba(255, 0, 0, 1);
}

.ekyc-button {
  margin-top: 50px;
  background-color: var(--color-primary);
  border: var(--color-primary) solid 1px;
  text-align: center;
  padding: 16px 50px;
  color: var(--color-white);
  font-size: 16px;
}

@media (max-width: 640px) {
  .ekyc-button {
    padding: 8px 25px;
    margin-top: 35px;
  }
}

.ekyc-button:hover {
  cursor: pointer;
  transform: scale(102%);
  transition: 0.1s all linear;
  filter: brightness(105%);
}

.ekyc-button.disable {
  pointer-events: none;
  color: var(--color-gray);
  border: #7a808620 solid 1px;
  background-color: #e1e3e5;
}

.ekyc-row-button {
  display: flex;
  gap: min(60px, 8vw);
}

/* ekyc intro */

.ekyc-intro-wrap {
  margin: min(120px, 8vh) auto 0;
  width: 90vw;
  max-width: var(--max-width-ekyc);
}

.ekyc-intro__list-card {
  margin: 20px auto 0;
  max-width: 80%;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.ekyc-intro__card {
  display: flex;
  flex-basis: 106px;
  flex-direction: column;
}

.ekyc-intro__card__img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: bottom;
}

.ekyc-intro__card__text {
  margin-top: 8px;
  padding: 0 12px;
  max-width: 100%;
  font-size: 14px;
  color: var(--color-black);
}

.ekyc-try-cam {
  margin-top: 6px;
}
.ekyc-try-cam__message {
  font-size: 12px;
}

.ekyc-try-cam__button {
  color: var(--color-primary);
  text-decoration: none;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .ekyc-intro__card__text {
    padding: 0 4px;
  }
}

.ekyc-camera-card {
  width: 100%;
  max-width: 90vw;
  margin-top: 20px;
}

.ekyc-camera {
  aspect-ratio: 16/10;
  width: 80%;
  height: 80%;
  /* border-radius: 8px;
  overflow: hidden; */
}

@media (max-width: 640px) {
  .ekyc-camera {
    aspect-ratio: 16/10;
    width: 100%;
    height: 100%;
    /* border-radius: 8px;
    overflow: hidden; */
  }
}


.ekyc-face-wrap .ekyc-camera {
  aspect-ratio: 4/5 !important;
  width: 80vw !important;
  max-width: calc(var(--max-width-ekyc)*0.8);

}

.ekyc-camera__video {
  background-color: var(--color-black);
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
  border-radius: 8px;
}

.ekyc-camera__frame {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  object-position: center center;
  object-fit: fill;
  width: 94%;
  height: 92%;
  /* background-color: #e1e3e5; */
}

.ekyc-camera__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--color-black);
  border-radius: 8px;
  margin: auto;
}

.ekyc-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10001;
  background-color: rgba(0, 0, 0, 0.85);
}

.ekyc-popup__main {
  background-color: var(--color-white);
  padding: 16px 24px;
  border-radius: 8px;
  min-width: 50vw;
  max-width: 90vw;
}

.ekyc-popup__confirm {
  margin-bottom: 30px;
  min-width: min(60vw, 400px);
}

.ekyc-popup__confirm__title {
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 12px;
}

/* ekyc front */
.ekyc-front-wrap {
  margin: min(120px, 8vh) auto 0;
  width: 90vw;
  max-width: var(--max-width-ekyc);
}

/* ekyc back */
.ekyc-back-wrap {
  margin: min(120px, 8vh) auto 0;
  width: 90vw;
  max-width: var(--max-width-ekyc);
}

/* ekyc face */
.ekyc-face-wrap {
  margin: min(120px, 8vh) auto 0;
  width: fit-content;
  min-width: min(60vw, 400px);
  max-width: 90vw;
}

/* ekyc liveliness */

.ekyc-liveliness-wrap .ekyc-camera {
  aspect-ratio: 5/5 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(var(--max-width-ekyc)*0.9) !important;
  /* border-radius: 50%; */
  /* overflow: hidden; */
}


.ekyc-liveliness-wrap .ekyc-camera__video {
  border-radius: 50%;
  width: 77% !important;
  height: 77% !important;
}


.ekyc-liveliness-wrap {
  margin: min(120px, 8vh) auto 0;
  width: 90vw;
  max-width: var(--max-width-ekyc);
}


.ekyc-liveliness-wrap .ekyc-camera__frame {
  width: 100%;
  height: 100%;
}

/* ekyc submit */

.ekyc-submit-wrap {
  margin: min(120px, 8vh) auto 0;
  min-width: min(60vw, 400px);
  max-width: min(620px, 90vw);
}

.ekyc-preview{
  margin: min(80px, 5vh) auto 0;
  min-width: min(60vw, 400px);
  max-width: min(620px, 90vw);
}

.ekyc-preview__title {
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  text-align: left;
}

.ekyc-preview__item{
  border-radius: 8px;
  /* background-color: #F4F4F5; */
  padding: 8px;
  border: var(--color-primary) solid 1px;
  margin-bottom: 10px;
}
.ekyc-preview__image{
  min-width: 55%;
  height: 120px;
  object-fit: contain;
  background-color: #D3F7FF;
  border-radius: 8px;
}

.ekyc-preview__status{
 padding: 0 20px;
}


.ekyc-preview__status img{
  margin-right: 8px;
}


.ekyc-submit__main {
  width: 100%;
  border: var(--color-primary) solid 1px;
  border-radius: 8px;
  padding: 40px 25px;
  display: grid;
  grid-template-columns: 5fr 3fr;
}

.ekyc-submit__image {
  width: 80%;
  margin: 0 auto;
}

.ekyc-submit__image__item {
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 45px;
}

@media (max-width: 640px) {
  .ekyc-submit__main {
    grid-template-columns: 5fr 0fr;
  }
}

/* ekyc result */

.ekyc-result-wrap {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ekyc-result__image {
  margin: 30px auto 40px;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.ekyc-result__message {
  font-size: 16px;
  color: var(--color-primary);
  font-weight: 500;
  padding: 0 30px;
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ekyc-popup-loading {
  border: #1080ec 4px solid;
  border-top-color: transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.slide-image__img {
  width: 95vw;
  max-width: 700px;
  height: 70vh;
  object-fit: contain;
}

.animation-fade {
  animation: fade 0.7s;
}

.slide-image__close,
.slide-image__prev,
.slide-image__next {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  transition: 0.5s ease;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 28px;
}

.slide-image__prev:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) translateX(-10%);
}

.slide-image__next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) translateX(10%);
}

.slide-image__close{
  position: fixed;
  top: 20px;
  right: 20px;
}

.slide-image__prev {
  top: 50%;
  left: -70px;
  transform: translateY(-50%);
}

.slide-image__next {
  top: 50%;
  right: -70px;
  transform: translateY(-50%);
}

.slide-image__thumbnail{
  position: absolute;
  bottom: -15px;
  display: flex;
  justify-content: center;
  width: 100%;

}

.slide-image__thumbnail__dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 8px;
  background-color: var(--color-gray);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.slide-image__thumbnail__dot:hover,  .slide-image__thumbnail__dot.active{
  background-color: var(--color-stroke);
}


.slide-image__button-recheck{
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  /* transform: translateY(-100%); */
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .slide-image__prev, .slide-image__next {
    width: 40px;
    height: 40px;
    font-size: 20px;
    /* top: 101%; */
    z-index: 1;
  }

  .slide-image__prev{
    left: 5px;
  }

  .slide-image__next{
    right: 5px;
  }
}


.wrap-arrow-liveliness{
  position: relative;
  width: 50px;
  height: 35px;
  background-color: var(--color-primary);
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;

}

.arrow-liveliness {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transform: rotate(90deg); */
  margin: -4px;
  cursor: pointer;
}

.arrow-liveliness span {
  display: block;
  width: 12px;
  height: 12px;
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
  transform: rotate(45deg);
  margin: -8px;
  animation: arrow-animate 2s infinite;
}

.arrow-liveliness span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-liveliness span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes arrow-animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}
