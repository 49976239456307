.w-full{
  width: 100%;
}

.h-full{
  height: 100%;
}

.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

.mt-0{
  margin-top: 0px;
}

.mb-0{
  margin-bottom: 0px;
}

.text-center {
  text-align: center;
}

.text-danger{
  color: var(--color-error);
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-xl {
  font-size: 20px;
}

.text-3xl {
  font-size: 30px;
}

.font-medium {
  font-weight: 500px;
}

.font-semibold {
  font-weight: 600px;
}

.font-bold {
  font-weight: 700;
}

.mb-6 {
  margin-bottom: 24px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.cursor-pointer{
  cursor: pointer;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
